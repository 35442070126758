module.exports = {
    webDevelopment: {
        title: "Web <br/> development",
        tooltip: "We only squeeze the best from the fruits of the joint experience. We design <strong>responsive</strong> and fully personalized websites. To this end, we combine an intuitive content management system that allows you to change the contents of ReactJS yourself, used to create unconventional graphical interfaces.",
    },
    graphics: {
        title: "Graphic <br/> projects",
        tooltip: "By creating visual identification, we oppose the schemes and the awareness of your brand <strong>grows</strong>. We offer comprehensive and professional graphic designs that are characterized by a concept refined with the highest precision.",
    },
    seo: {
        title: "SEO",
        tooltip: "We position websites and stores at the local and global level. We make sure your site is <strong>high</strong> on organic search results. Positioning is done together with SEO activities.",
    },
    maintenance: {
        title: "Application maintenance and&nbsp;development",
        tooltip: "We offer support and development of a business application, making it <strong>attractive</strong> to users. At work, we use reliable solutions that guarantee a modern message. With great efficiency, we are able to explore any programming language on which an existing website functions.",
    },
}
