import React from "react";
import SEO from "../../components/seo";
import Carousel from "../../components/carousel/carousel";
import {OfferOptions} from "../../components/carousel/configs";
import {Clients} from "../../data/clients";
import Image from "../../components/image/image";
import offer from "../../data/offer-tooltips/en";
import TooltipText from "../../components/tooltip/tooltipText";
import "./offer.scss";

const OfferPage = (props) => {
    const offerContent = Clients().map(item =>
        <a href={item.link} target='_blank' rel="noopener noreferrer">
            <Image fluid
                   image={item.image[0].node.fluid}
                   alt={item.alt}
                   fit="cover"
            />
        </a>
    );
    return (
        <div location={props.location}>
            <SEO title="Our Offer" language="en"/>
            <div className="offer-wrap">
                <div className="container">
                    <h2 className="offer">
                        In our offer you will find:
                    </h2>
                    <div className="circle-wrap">
                        <div className="circle">
                            <TooltipText placement="bottom"
                                         classname="circle__text"
                                         text={offer.webDevelopment.title}
                                         tooltipText={offer.webDevelopment.tooltip}
                            />
                        </div>
                        <div className="circle">
                            <TooltipText placement="bottom"
                                         classname="circle__text"
                                         text={offer.graphics.title}
                                         tooltipText={offer.graphics.tooltip}
                            />
                        </div>
                        <div className="circle">
                            <TooltipText placement="bottom"
                                         classname="circle__text"
                                         text={offer.seo.title}
                                         tooltipText={offer.seo.tooltip}
                            />
                        </div>
                        <div className="circle">
                            <TooltipText placement="bottom"
                                         classname="circle__text"
                                         text={offer.maintenance.title}
                                         tooltipText={offer.maintenance.tooltip}
                            />
                        </div>
                    </div>
                    <div className="features-box">
                        <div className="text-box">
                            <p className="feature">
                                Make free the dormant potential of your company. Work with professionals whose
                                passion is to create the Internet.
                            </p>
                        </div>
                        <div className="features-slogans">
                            <p className="absolute front-end">
                                #front-end
                            </p>
                            <p className="absolute passion">
                                #passion
                            </p>
                            <p className="absolute webdevelopment">
                                #webdevelopment
                            </p>
                            <p className="absolute graphicdesign">
                                #graphicdesign
                            </p>
                            <p className="absolute seo">
                                #seo
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="realizations">
                <div className="container">
                    <h3 className="realizations__title">
                        Selected projects
                    </h3>
                    <Carousel options={OfferOptions} content={offerContent} arrows={true}
                              arrowsClass="round gradient"/>
                </div>
            </div>
        </div>
    );
};

export default OfferPage;
